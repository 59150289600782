@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}

html, body, #root{
  height:100%;
  width: 100%; 
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #282c34;
  width: 100%;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;
}

.App-header {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
  flex-grow: 1;

  @include for-phone-only {
    height: 100px;
  }
  
  @include for-tablet-portrait-up {
    height: 80px;
  }

  @include for-tablet-landscape-up {
    height: 100px;
  }

  @include for-desktop-up {
    height: 100px;
  }
}

.logoBox{
  padding: .5rem;
}

.logoSvg{
  width:300px;
}

.logo{
  stroke-width: 1px;
  stroke:#fff;
  fill: none; 
  font-family:sans-serif;
  font-size:calc(20px + .1vmin);
}

.App-link {
  color: #61dafb;
}

.List {
  display: flex;
  flex-direction: column;
  flex-basis: content;
  justify-content: center;
  flex-grow: 10;
  padding-inline-start: 0;
  height: 80%;
  width: 100%;
  overflow-y: auto;
}

.liDiv{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #545a66;
  margin-left: calc(5px + 10vw);
  margin-right: calc(5px + 10vw);
  margin-bottom: 20px;
  padding: .5rem;
  border-radius: 4px;
  overflow: hidden;
}

.liImgDiv{
  width: 70px;
  align-self: center;
  flex-grow: 0;
  opacity: 1;
}
.liImgDiv img{
  height: 70px;
  clip-path: circle(34px at center);
}

.li{
  text-align: left;
  font-size: calc(9px + 0.5vw);
  flex-grow: 4;
  z-index: 2;
  margin-left: 1em;
}

a{
  color: inherit;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

.neon-flicker {
	animation: neon-flicker 2s linear both;
}

.fade-in {
	animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-3-21 11:48:23
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-flicker-in-glow
 * ----------------------------------------
 */
 
 @keyframes neon-flicker {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
    text-shadow: none;
  }
  10.1% {
    opacity: 1;
    text-shadow: none;
  }
  10.2% {
    opacity: 0;
    text-shadow: none;
  }
  20% {
    opacity: 0;
    text-shadow: none;
  }
  20.1% {
    opacity: 1;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.25);
    filter: drop-shadow(0 0 .05vmin white)
  }
  20.6% {
    opacity: 0;
    text-shadow: none;
  }
  30% {
    opacity: 0;
    text-shadow: none;
  }
  30.1% {
    opacity: 1;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.45), 0 0 15px rgba(255, 255, 255, 0.25);
    filter: drop-shadow(0 0 .05vmin white)
  }
  30.5% {
    opacity: 1;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.45), 0 0 15px rgba(255, 255, 255, 0.25);
    filter: drop-shadow(0 0 .1vmin white)
  }
  30.6% {
    opacity: 0;
    text-shadow: none;
  }
  45% {
    opacity: 0;
    text-shadow: none;
  }
  45.1% {
    opacity: 1;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.45), 0 0 15px rgba(255, 255, 255, 0.25);
    filter: drop-shadow(0 0 .2vmin white)
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.45), 0 0 15px rgba(255, 255, 255, 0.25);
    filter: drop-shadow(0 0 .1vmin white)
  }
  55% {
    opacity: 1;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.45), 0 0 15px rgba(255, 255, 255, 0.25);
    filter: drop-shadow(0 0 .2vmin white)
  }
  55.1% {
    opacity: 0;
    text-shadow: none;
  }
  57% {
    opacity: 0;
    text-shadow: none;
  }
  57.1% {
    opacity: 1;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.55), 0 0 15px rgba(255, 255, 255, 0.35);
    filter: drop-shadow(0 0 .15vmin white)
  }
  60% {
    opacity: 1;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.55), 0 0 15px rgba(255, 255, 255, 0.35);
    filter: drop-shadow(0 0 .2vmin white)
  }
  60.1% {
    opacity: 0;
    text-shadow: none;
  }
  65% {
    opacity: 0;
    text-shadow: none;
  }
  65.1% {
    opacity: 1;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.55), 0 0 15px rgba(255, 255, 255, 0.35), 0 0 20px rgba(255, 255, 255, 0.1);
    filter: drop-shadow(0 0 .25vmin white)
  }
  75% {
    opacity: 1;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.55), 0 0 15px rgba(255, 255, 255, 0.35), 0 0 20px rgba(255, 255, 255, 0.1);
    filter: drop-shadow(0 0 .25vmin white)
  }
  75.1% {
    opacity: 0;
    text-shadow: none;
  }
  77% {
    opacity: 0;
    text-shadow: none;
  }
  77.1% {
    opacity: 1;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.55), 0 0 15px rgba(255, 255, 255, 0.4), 0 0 25px rgba(255, 255, 255, 0.2), 0 0 20px rgba(255, 255, 255, 0.1);
    filter: drop-shadow(0 0 .2vmin white)
  }
  85% {
    opacity: 1;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.55), 0 0 15px rgba(255, 255, 255, 0.4), 0 0 25px rgba(255, 255, 255, 0.2), 0 0 20px rgba(255, 255, 255, 0.1);
    filter: drop-shadow(0 0 .15vmin white)
  }
  85.1% {
    opacity: 0;
    text-shadow: none;
  }
  86% {
    opacity: 0;
    text-shadow: none;
  }
  86.1% {
    opacity: 1;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.6), 0 0 15px rgba(255, 255, 255, 0.45), 0 0 25px rgba(255, 255, 255, 0.25), 0 0 20px rgba(255, 255, 255, 0.1);
    filter: drop-shadow(0 0 .2vmin white)
  }
  100% {
    opacity: 1;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.6), 0 0 15px rgba(255, 255, 255, 0.45), 0 0 25px rgba(255, 255, 255, 0.25), 0 0 20px rgba(255, 255, 255, 0.1);
    filter: drop-shadow(0 0 .2vmin white)
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-3-21 11:58:41
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
 @keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}